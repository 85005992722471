.App{
    .Login{
        display:flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        .Wrapper{
            width: 1000px;
            margin-top: 32px;
        }
    }
}

body {
    background: #FFF;
}