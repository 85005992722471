.ant-list-item{
    transition: 300ms ease-in;
    &:hover,
    &.hovered{
        background: #FFF;
        cursor: pointer;
        border-radius: 8px 0px 0px 8px;
        opacity: 1 !important;
    }
    &.wohovered{
        opacity: 0.55;
    }
    padding: 12px 24px;
}

.Accounting{
    *:not(button){
        user-select: none;
    }
    &--ReportFrame{
        background: #FFF;
        padding: 24px;
        border-radius: 0px 8px 8px 0px;
        display: flex;
        .ant-empty {
            align-self: center;
            width: 100%;
        }
        .ant-form{
            width: 100%;
        }
    }
    .Wrapper{
        margin-top: 0px !important;
    }
    &--DownloadBtn{
        float: right;
    }
}

.isMobile {
    form{
        .ant-col-16{
            margin-bottom: 4px !important;
        }
    }
}