.ant-page-header{
    padding: 16px 0px;
    .ant-page-header-heading-left{
        flex-direction: column;
        align-items: flex-start;
        margin: 0px;
        *{
            text-align: left;
        }
    }
}

.react-grid-Row{
    .react-grid-Cell:first-child{
      background: #f9f9f9 !important;
      .react-grid-Cell__value div{
        font-weight: bold !important;
      }
    }
  }
  .react-grid-HeaderCell{
    * {
      font-weight: bold !important;
    }
  }