.Dashboard--WelcomeImage{
    max-width: 75%;
    .isDesktop &{
        max-height: 240px;
    }
}

.isMobile {
    .ant-col:last-child{
        margin-bottom: 32px;
    }
}