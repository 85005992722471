@import '~antd/dist/antd.css';

@import './Views/Login/index.scss';
@import './Assets/global.scss';

/* .ant-page-header{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

.ant-page-header-heading-left{
    flex-direction: column !important;
    align-items: baseline !important;
} */

.WarehouseCreateMission{
    .ant-upload-drag{
        margin-right: 32px !important;
        width: calc(100% - 16px);
    }
    .ant-upload-list-null{
        display: none;
    }
    .ant-tabs{
        width: calc(100% - 32px);
        .ant-tabs-nav{
            margin-bottom: 0px;
        }
        .ant-tabs-content-holder{
            background: #fff;
            padding-left: 16px;
            padding-top: 24px;
            padding-right: 16px;
        }
    }
    .MisionStep3{
        width: calc(100% - 32px);
    }
    .ant-tabs-tab-disabled{
        cursor: default !important;
        user-select: none !important;
        background: none !important;
        border: 0px !important;
        color: #000;
    }
}

.EditorContent{
    background: #FFF;
    width: calc(100% - 32px);
    margin-top: 24px;
}

.titleMission{
    width: calc(100% - 16px);
    margin-bottom:16px;
}

.CardPreviewMission{
    // z-index:9999;
    * {
        font-size: 90%;
    }
    .Insignia{
        height: 45px;
        object-fit: contain;
        margin-bottom: 8px;
    }
    p.Texto{
        line-height: 10px;
        text-align: justify;
        font-size: 9px;
        max-height: 114px;
        height: 114px;
        word-wrap: break-word !important;
    }
    p.NombreMision{
        line-height: 10px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2px;
    }
    ul.ant-card-actions{
        background: #FFF;
        z-index:999;
        display: inline-block;
        width: 100%;
    }
    span.ant-tag{
        font-size: 8px;
        margin-right: 0px;
        margin-top: 2px;
        margin-bottom: 8px;
    }
    .ant-card-body{
        text-align: center;
        padding: 12px;
    }
    .ant-card-head{
        padding: 0px 12px;
        min-height: 32px;
        .ant-card-head-title{
            padding: 8px 0px;
        }
    }
}

.ModalResourceSelect{
    .ant-modal-header{
        * {
            color: #fff;
        }
        background:#1890ff;
        svg, svg path{
            fill: #FFF;
        }
    }
    .ant-modal-body{
        display: flex;
        flex-direction: column;
        & > div {
            display: flex;
            flex-direction: column;
            small {
                margin-bottom: 8px;
            }
            & > div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap !important;
            }
        }
        * {
            user-select: none;
        }
    }
    .ant-row{
        margin-bottom: 24px;
        // & > .ant-col > div[role='button']{
            // margin: 0px !important;
        // }
        transition: all ease 150ms;
        display: flex;
        flex-direction: column;
        width: 25%;
        text-align: center;
        .ant-col:last-child{
            padding: 0px 24px;
        }
    }
    .ant-modal-footer{
        .ant-row{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0px;
            .ant-col:last-child{
                padding-right: 0px !important;
            }
        }
    }
}

.AddedNewItem{
    display: flex;
    text-align: left;
    span, strong{ color: #000; }
    span.anticon{ color: green; font-size: 24px; margin-top: 8px; }
    margin-bottom: 64px;
}

.InputAddRadioOption{
    float: right;
    margin-right: 12px;
    color: #4caf50;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
}


.InputRemoveRadioOption{
    float: right;
    margin-right: 12px;
    color: #f44336;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
}

.ContainerButtons{
    display: flex;
    button:first-child{
        margin-right: 8px;
    }
}

.ant-modal.ModalRed{
    .anticon-exclamation-circle{
        color: #f44336 !important;
    }
    .ant-modal-confirm-btns{
        .ant-btn-primary{
            background: #f44336 !important;
            border-color: #f44336 !important;
        }
    }
}

.ant-modal.ModalYellow{
    .anticon-exclamation-circle{
        color: #ffc107 !important;
    }
    .ant-modal-confirm-btns{
        .ant-btn-primary{
            background: #ffc107 !important;
            border-color: #ffc107 !important;
        }
    }
}

.SpaceConditional{
    min-height: 560px;
    .ant-col{
        background: #f3f7fd;
        padding: 24px;
        .ant-result-title{
            font-size: 16px;
            width: 70%;
            margin-left: 15%;
        }
    }
    margin-bottom:24px;
}

.Step {
    & img {
        width: 32px;
        height: 32px;
    }
}

.DrawerContent {
    .Video {
        video {
            max-height: 380px;
            margin-bottom: 24px;
        }
    }
    .ant-descriptions-row {
        .ant-descriptions-item-label {
            font-weight: bold;
            font-variant: small-caps;
            padding: 8px 24px;
        }
        .ant-descriptions-item-content {
            button.ant-btn {
                float: right;
            }
            .ant-rate {
                min-width: 140px;
            }
        }
    }
}

.TitleDraweImage {
    width: 32px;
}

.ant-drawer-title {
    display: flex;
    align-items: center;
    gap: 10px;
}