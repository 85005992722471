body{
    background: #F3F7FD;
}
.ant-spin-nested-loading{
    .isDesktop &{
        min-height: calc(75vh - 136px - 48px);
    }
    .isMobile &{
        min-height: 50vh;
    }
}

.ant-statistic-content-suffix{
    opacity: 0.45;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
    background-color: #b4101b;
}

.AppFrame{
    .Header{
        padding: 12px 24px 0px;
        &-dark{
            background: #c20e1a !important;
        }
        ul.ant-menu{
            &.ChildrenMenu{
                padding-bottom:8px;
            }
            &.RootMenu{
                margin-bottom: 16px;
                .ant-menu-item{
                    border-radius: 32px;
                    &-selected{
                        background-color: #f01220;
                        
                    }
                }
                .ant-menu-submenu-title{
                    color: #FFF;
                }
            }
            &-dark{
                background: #c20e1a !important;
                .ant-menu-item-selected{
                    background-color: inherit;
                }
            }
            .UserMenu{
                float: right;
            }
        }
        svg.logo{
            padding: 8px;
            margin-right: 24px;
        }
        hr{
            border: 0;
            height: 1px;
            background-color: #f01220;
        }
    }

    .Wrapper{
        margin: 24px;
    }
}

.AppFrame.isMobile{
    .MobileInfo{
        min-height: 46px;
        color: #FFF;
    }
    ul.ant-menu{
        &.RootMenu{
            display: flex !important;
            margin-top: 8px;
            .ant-menu-item{
                flex-basis: 100%;
                text-align: center;
                &:first-child{ text-align: left; }
                &:first-child{ text-align: right; }
                
            }   
        }
    }
}